import * as React from "react";

const AboutMe = ({ aboutMeContent }) => {
    const CONTENT_URL = "https://content.shaneducksbury.com"

    if(aboutMeContent){
        return(
            <>
                <section className="section-wrapper">
                    <h2>A Little About Me</h2>
                    <div className="card aboutme-card">
                        <div className="card-image card-image-vertical">
                            <img src={CONTENT_URL + aboutMeContent.Headshot[0].url} alt="" />
                        </div>
                        <div className="card-content">
                            <h3>{aboutMeContent.Headline}</h3>
                            {aboutMeContent.Information.data.Information.split("\n").map(line => {
                                return(<p>{line}</p>)
                            })}
                            <p>Here are some of the languages and tools I work with:</p>
                            <ul className="aboutme-tool-list">
                                {aboutMeContent.Languages.split(",").map(item => {
                                    return(<li className="tool-text">{item}</li>)
                                })}
                            </ul>
                            <a href="https://github.com/shane-ducksbury" target="_blank">View My GitHub ▶</a>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default AboutMe;
